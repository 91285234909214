<template>
    <span>
        <span v-if="$i18n.locale == 'en' || singleLanguageName == 'en'">
            <img src="@/assets/img/login/logo.en.svg" alt="GEOSPLIT Logo" >
        </span>
        <span v-else>
            <img src="@/assets/img/login/logo.ru.svg" alt="GEOSPLIT Logo">
        </span>
    </span>    
</template>

<script>
import { SINGLE_LANGUAGE_NAME } from '../config/constants';

export default {
    data() {
        return {
            singleLanguageName: SINGLE_LANGUAGE_NAME
        }
    }
}
</script>
