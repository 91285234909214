<template>
    <div>
        <div class="login-content">
        <div class="login-form" v-show="isCheched == false && !isIdInvalid">
            <div class="login-logo">
                <CompanyLogo></CompanyLogo>
            </div>

            <div>
                <div class="login-title chp-title">Восстановление пароля</div>

                <!--  INFO: @keyup.enter.native="login" ДЛЯ el-input -->
                <div class="login-input-group login-input-group-pass">
                    <input type="password" v-show="!isShowPass" v-model="newPass" class="login-input pass-input" placeholder="Новый пароль">
                    <input type="text" v-show="isShowPass" v-model="newPass" class="login-input pass-input" placeholder="Новый пароль">
                    <span class="show-pass-btn" v-show="!isShowPass" @click="changeShowPass">Показать</span>
                    <span class="show-pass-btn" v-show="isShowPass" @click="changeShowPass">Скрыть</span>
                </div>
                <div class="login-input-group login-input-group-pass">
                    <input type="password" v-show="!isShowPass2" v-model="newPassRepeat" class="login-input pass-input" placeholder="Новый пароль">
                    <input type="text" v-show="isShowPass2" v-model="newPassRepeat" class="login-input pass-input" placeholder="Новый пароль">
                    <span class="show-pass-btn" v-show="!isShowPass2" @click="changeShowPass2">Показать</span>
                    <span class="show-pass-btn" v-show="isShowPass2" @click="changeShowPass2">Скрыть</span>
                </div>
                <button @click="changePassword" class="login-button clickable" :disabled="!resedId">Изменить</button>
            </div>
        </div>
        <div class="centred-by-screen zz" v-show="isIdInvalid">{{$t('passChange.urlInvalid')}}</div>
        <div class="login-footer">© 2021 ГеоСплит</div>
    </div>
    </div>
</template>

<script>
import serverMd from '../api/server-model';
import utilites from '../lib/utilites';

import CompanyLogo from '../components/CompanyLogo.vue';

export default {
    data() {
        return {
            isCheched: true, //проверяем, валидна ли ссылка
            isIdInvalid: false,
            isLoading: false,
            newPass: null,
            newPassRepeat: null,
            isShowPass: false,
            isShowPass2: false,
            resedId: null
        };
    },
    methods: {
        opend() {
            if(this.$router && this.$router.currentRoute && this.$router.currentRoute.params && this.$router.currentRoute.params.id) {
                this.resedId = this.$router.currentRoute.params.id;
                this.checkUrl()
            } else {
                //INFO: вообще то сюда не попадем, тк роутер без id будут редиректит на 404, что в принципе норм
                this.isIdInvalid = true;
            }
        },
        checkUrl() {
            this.isCheched = true;
            serverMd.searchByModel('restorePass', {id: this.resedId}, data => {
                this.isCheched = false;
                if(data && data[0] && data[0].count == 1) {
                    //INFO: тупо показываем подсказку по паролю при загрузке формы
                    utilites.checkPassword('123Qwerty', false, true);
                } else {
                    this.isIdInvalid = true;
                    // this.$router.push('../login');
                }
            });
        },
        changePassword() {
            this.isWrongNewPass = false;
            this.isWrongNewPassRepeat = false;
            if(this.newPass != this.newPassRepeat) {
                this.$store.commit('addMessageError', this.$t('passChange.changeMismatch'));
                this.isWrongNewPassRepeat = true;
            } else {
                if(!utilites.checkPassword(this.newPass)) {
                    this.isWrongNewPass = true;
                } else {
                    serverMd.saveModel('restorePass', {newPass: this.newPass, id: this.resedId}, isSuccess => {
                        if(isSuccess) {
                            this.$store.commit('addMessageInfo', this.$t('passChange.changed'));
                            this.$router.push('../login');
                        }
                    });
                }
            }
        },
    changeShowPass() {
      this.isShowPass = !this.isShowPass;
    },
    changeShowPass2() {
      this.isShowPass2 = !this.isShowPass2;
    }
    },
    created() {
        this.opend();
    },
    components: {
        CompanyLogo
    }
};
</script>

<style src="./login.css" scoped></style>
<style scoped>
.chp-title {
    margin-bottom: 60px;
}
.pass-input {
    width: 220px;
}
.show-pass-btn {
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
  cursor: pointer;
}
.zz {
    margin-left: -388px;
    font-size: 30px;
    font-weight: 300;
}
</style>